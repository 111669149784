var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog"},[_c('div',{staticClass:"gap-2"}),_c('ButtonNav'),_c('div',{staticClass:"container p-5 mt-5 mb-5"},[_c('h4',{staticClass:"text-center"},[_vm._v("BLOG NEWS")]),_c('h6',{staticClass:"mt-5 text-center"},[_vm._v("Latest news update")]),(!_vm.isMobile)?_c('div',{staticClass:"gap"}):_vm._e(),_vm._l((_vm.posts),function(post){return _c('div',{key:post.index,class:{ 'mt-5': _vm.isMobile }},[_c('div',{staticClass:"row justify-content-between align-items-center"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-5"},[_c('div',{staticClass:"blog-card p-4",on:{"click":function($event){return _vm.togglePost(post)}}},[_c('div',{staticClass:"bg-image",style:({
                backgroundImage:
                  'url(' +
                  _vm.ipfs +
                  '/ipfs/' +
                  post.metadata.preview_img.replace('ipfs://', '') +
                  ')',
              })}),_c('div',{staticClass:"blog-card-content"},[_c('div',{staticClass:"blog-title"},[_vm._v(_vm._s(post.metadata.title))]),_vm._m(0,true)])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-5",class:{ 'mt-5': _vm.isMobile }},[_c('div',[_c('div',{staticClass:"d-flex align-items-center mt-1 mb-3"},[_c('div',{staticClass:"blog-date secondary-color"},[_vm._v(" "+_vm._s(_vm.moment(post.metadata.timestamp).format("MMM D, YYYY"))+" ")])]),_c('div',{staticClass:"blog-title secondary-color"},[_vm._v(" "+_vm._s(post.metadata.title)+" ")]),(!_vm.isMobile)?_c('div',{staticClass:"gap"}):_vm._e(),_c('div',{staticClass:"btn-blog",class:{ 'mt-5': _vm.isMobile },on:{"click":function($event){return _vm.togglePost(post)}}},[_vm._v(" READ ")])])])]),_c('Transition',{attrs:{"name":"slide"}},[(_vm.isOpen === post.title)?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-md-10 col-lg-8 pt-5"},[_c('p',{staticClass:"blog-text",domProps:{"innerHTML":_vm._s(post.metadata.body_text)}})])]):_vm._e()]),_c('div',{staticClass:"divider mt-5 mb-5"})],1)})],2),_c('FooterExt')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-between mt-5"},[_c('img',{attrs:{"src":require("../assets/images/arrow.svg"),"alt":""}}),_c('p',{staticClass:"blog-cta"},[_vm._v("read more")])])
}]

export { render, staticRenderFns }