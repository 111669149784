var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"services pt-4"},[_c('ButtonNav'),_c('div',{staticClass:"container-fluid pd-container",style:([!_vm.isMobile ? { marginTop: '80px' } : { marginTop: '50px' }])},[_c('div',{staticClass:"row margin-btm"},[_c('div',{staticClass:"col-12"},[_c('h2',{},[_vm._v(_vm._s(_vm.$t("menu.service")))])])])]),_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row margin-btm pt-5"},[_c('div',{staticClass:"col-12 service-content"},[_c('MarqueeText',{attrs:{"repeat":10,"duration":_vm.$t('service.firstBanner').length * 0.5},on:{"click":function($event){_vm.showTextFirst = ''}}},[_c('h4',[_vm._v(_vm._s(_vm.$t("service.firstBanner"))+" ")])]),_c('div',{staticClass:"single-service position-one transform-rotate-one hoverPointer",class:{
            active: _vm.showTextFirst === 'blockchain',
            'lft-40': !_vm.isMobile,
          },on:{"click":function($event){if (_vm.showTextFirst === 'blockchain') {
              _vm.showTextFirst = '';
            } else {
              _vm.showTextFirst = 'blockchain';
              _vm.showTextSecond = '';
              _vm.showTextThird = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.blockchain")))])]),_c('div',{staticClass:"single-service position-two transform-rotate-two hoverPointer",class:{
            active: _vm.showTextFirst === 'metaverse',
            'lft-10': !_vm.isMobile,
          },on:{"click":function($event){if (_vm.showTextFirst === 'metaverse') {
              _vm.showTextFirst = '';
            } else {
              _vm.showTextFirst = 'metaverse';
              _vm.showTextSecond = '';
              _vm.showTextThird = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.metaverse")))])]),_c('div',{staticClass:"single-service position-five transform-rotate-three hoverPointer",class:{
            active: _vm.showTextFirst === 'ai',
            'lft-75 me-5 mb-4': !_vm.isMobile && !_vm.isTablet && !_vm.isMiddleScreen,
          },on:{"click":function($event){if (_vm.showTextFirst === 'ai') {
              _vm.showTextFirst = '';
            } else {
              _vm.showTextFirst = 'ai';
              _vm.showTextSecond = '';
              _vm.showTextThird = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.ai")))])])],1),_c('div',{staticClass:"col-8 offset-2 block-text"},[(_vm.showTextFirst == '')?_c('p',[_vm._v(_vm._s(_vm.$t("service.firstDescription")))]):_vm._e(),(_vm.showTextFirst == 'blockchain')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.blockchain_description"))+" ")]):_vm._e(),(_vm.showTextFirst == 'ai')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.ai_description"))+" ")]):_vm._e(),(_vm.showTextFirst == 'metaverse')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.metaverse_description"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row margin-btm pt-5"},[_c('div',{staticClass:"col-12 service-content"},[_c('MarqueeText',{attrs:{"repeat":10,"duration":_vm.$t('service.secondBanner').length * 0.5,"reverse":""},on:{"click":function($event){_vm.showTextSecond = ''}}},[_c('h4',{staticClass:"hoverPointer"},[_vm._v(_vm._s(_vm.$t("service.secondBanner"))+" ")])]),_c('div',{staticClass:"single-service position-one transform-rotate-two hoverPointer",class:{
            active: _vm.showTextSecond === 'marketplace',
            'lft-15': !_vm.isMobile,
          },on:{"click":function($event){if (_vm.showTextSecond === 'marketplace') {
              _vm.showTextSecond = '';
            } else {
              _vm.showTextSecond = 'marketplace';
              _vm.showTextFirst = '';
              _vm.showTextThird = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.marketplace")))])]),_c('div',{staticClass:"single-service position-three transform-rotate-two hoverPointer",class:{ active: _vm.showTextSecond === 'app', 'lft-40': !_vm.isMobile },on:{"click":function($event){if (_vm.showTextSecond === 'app') {
              _vm.showTextSecond = '';
            } else {
              _vm.showTextSecond = 'app';
              _vm.showTextFirst = '';
              _vm.showTextThird = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.app")))])]),_c('div',{staticClass:"single-service position-one transform-rotate-three hoverPointer",class:{
            active: _vm.showTextSecond === 'website',
            'lft-75': !_vm.isMobile,
          },on:{"click":function($event){if (_vm.showTextSecond === 'website') {
              _vm.showTextSecond = '';
            } else {
              _vm.showTextSecond = 'website';
              _vm.showTextFirst = '';
              _vm.showTextThird = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.website")))])])],1),_c('div',{staticClass:"col-8 offset-2 block-text"},[(_vm.showTextSecond == '')?_c('p',[_vm._v(" "+_vm._s(_vm.$t("service.secondDescription"))+" ")]):_vm._e(),(_vm.showTextSecond == 'marketplace')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.marketplace_description"))+" ")]):_vm._e(),(_vm.showTextSecond == 'app')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.app_description"))+" ")]):_vm._e(),(_vm.showTextSecond == 'website')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.website_description"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row margin-btm pt-5"},[_c('div',{staticClass:"col-12 service-content"},[_c('MarqueeText',{attrs:{"repeat":10,"duration":_vm.$t('service.thirdBanner').length * 0.5},on:{"click":function($event){_vm.showTextThird = ''}}},[_c('h4',[_vm._v(_vm._s(_vm.$t("service.thirdBanner"))+" ")])]),_c('div',{staticClass:"single-service position-one transform-rotate-one lft-50 hoverPointer",class:{ active: _vm.showTextThird === 'design' },on:{"click":function($event){if (_vm.showTextThird === 'design') {
              _vm.showTextThird = '';
            } else {
              _vm.showTextThird = 'design';
              _vm.showTextSecond = '';
              _vm.showTextFirst = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.design")))])]),_c('div',{staticClass:"single-service position-three transform-rotate-two lft-25 hoverPointer",class:{
            active: _vm.showTextThird === 'branding',
            'lft-40 ms-5': !_vm.isMobile && !_vm.isTablet,
          },on:{"click":function($event){if (_vm.showTextThird === 'branding') {
              _vm.showTextThird = '';
            } else {
              _vm.showTextThird = 'branding';
              _vm.showTextSecond = '';
              _vm.showTextFirst = '';
            }}}},[_c('p',{staticClass:"text-strong"},[_vm._v(_vm._s(_vm.$t("service.branding")))])])],1),_c('div',{staticClass:"col-8 offset-2 block-text"},[(_vm.showTextThird == '')?_c('p',[_vm._v(_vm._s(_vm.$t("service.thirdDescription")))]):_vm._e(),(_vm.showTextThird == 'design')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.design_description"))+" ")]):_vm._e(),(_vm.showTextThird == 'branding')?_c('p',{staticClass:"active-text fade-in"},[_vm._v(" "+_vm._s(_vm.$t("service.branding_description"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('Bubble',{attrs:{"resizeHeightBubble":_vm.isMobile || _vm.isTablet ? 350 : 250,"resizeWidthBubble":_vm.isMobile || _vm.isTablet ? 200 : 480}}),_c('a',{staticClass:"m-auto link-portfolio color-primary",class:{ 'w-50': !_vm.isMobile },staticStyle:{"font-size":"2.8rem"},attrs:{"href":"#/internal-project"}},[_vm._v(_vm._s(_vm.$t("service.mego_ticket")))])],1)]),_c('div',{staticClass:"gap"}),(_vm.isDesktop)?_c('div',{staticClass:"gap"}):_vm._e()]),_c('FooterExt'),_c('newFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }