var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:([!_vm.isMobile ? { marginTop: '80px' } : { marginTop: '50px' }])},[_c('ButtonNav'),_c('div',{staticClass:"container-fluid px-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('h2',{},[_vm._v(_vm._s(_vm.$t("portfolio.title")))])]),_c('div',{staticClass:"col-12 col-md-4"},[_c('h6',{staticClass:"mt-5 sub-title-project",class:{ 'ps-4': !_vm.isMobile }},[_vm._v(" "+_vm._s(_vm.$t("portfolio.description"))+". ")])])])]),_c('div',{staticClass:"container-fluid mt-5 p-0",class:{ 'mb-5': _vm.isMobile }},[_c('div',{staticClass:"text-center content-container",class:{ 'scrollable-container': !_vm.isDesktop && !_vm.isMiddleScreen }},_vm._l((_vm.projects),function(project,index){return _c('div',{key:index,staticClass:"link-portfolio",class:{
          'active-link-portfolio':
            _vm.$route.query.project != undefined
              ? decodeURIComponent(_vm.$route.query.project) == project.name
              : false,
          'hovering-link': _vm.activeLink === index,
        }},[_c('a',{class:{ 'list-portfolio': !_vm.isMobile },attrs:{"href":'/#/portfolio/' + project.name.split(' ').join('-')}},[_vm._v(_vm._s(project.name))])])}),0)]),(!_vm.isMobile)?_c('div',{staticClass:"gap"}):_vm._e(),_c('a',{staticClass:"link-title",attrs:{"href":"#/services"}},[_c('MarqueeText',{staticClass:"title-project mt-3",staticStyle:{"z-index":"1"},attrs:{"repeat":6,"duration":8}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("banner.portfolio"))+" ")])])],1),_c('FooterExt'),_c('newFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }